import { render, staticRenderFns } from "./myVideo.vue?vue&type=template&id=9d6e6fb6&scoped=true"
import script from "./myVideo.vue?vue&type=script&lang=js"
export * from "./myVideo.vue?vue&type=script&lang=js"
import style0 from "./myVideo.vue?vue&type=style&index=0&id=9d6e6fb6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d6e6fb6",
  null
  
)

export default component.exports